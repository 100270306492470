import React, { useRef } from "react";
import useOutsideClick from "../Utils/useOutsideClick";
import "./Modal.scss";

const Modal = ({ show, setShow, children }) => {
  const ref = useRef();

  useOutsideClick(ref, "modal__close", () => {
    setShow(false);
  });

  return (
    <div className={`modal ${show ? "modal--open" : ""}`}>
      <div className="modal__wrapper" ref={ref}>
        <button className="modal__close" onClick={() => setShow(false)}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
