import React, { useState, useEffect } from "react";
import useInterval from "@use-it/interval";
import axios from "axios";
import "react-h5-audio-player/lib/styles.css";
import Modal from "./Modal/Modal";
import logo from "./logoblue.jpg";
import "./main.scss";

const DELAY = 120000;

function App() {
  const [modal, setModal] = useState();
  const [showRequest, setShowRequest] = useState(true);

  useEffect(() => {
    axios
      .get(
        "https://fm.transmitir.me/api/station/2/requests?current=1&rowCount=10&searchPhrase=&_=1590547789064"
      )
      .then((res) => {
        if (res.status === 200) {
          setShowRequest(true);
        } else {
          setShowRequest(false);
        }
      })
      .catch((error) => {
        setShowRequest(false);
      });
  }, []);

  const refreshComments = (e) => {
    e.preventDefault();
    const iframe = document.querySelector(".chat iframe");
    iframe.src = iframe.src;
  };

  useInterval(() => {
    /*axios.get('https://fm.transmitir.me/api/station/2/requests').then(res => {
       if (res.status === 200) {
         setShowRequest(true);
       } else {
         setShowRequest(false);
       }
     }).catch(error => {
       setShowRequest(false);
     })*/
    const iframe = document.querySelector(".chat iframe");
    iframe.src = iframe.src;
  }, DELAY);

  return (
    <div className="app">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Blue Medellín" />
        </div>
      </header>

      <div className="player">
        <div className="rhap_container ">
          <iframe
            title="player"
            src="https://fm.transmitir.me/public/blue_medellin/embed"
            frameborder="0"
            allowtransparency="true"
            style={{
              width: "100%",
              minHeight: "135px",
              border: "0",
              height: "0em",
            }}
          ></iframe>
          ¡Si no puedes escuchar, dale pausa y vuelve a darle play!
          {showRequest ? (
            <button onClick={() => setModal(true)}>Pedir una canción</button>
          ) : (
            <></>
          )}
          <div className="ad-container">
            <ins
              class="adsbygoogle"
              style={{ display: "block" }}
              data-ad-format="fluid"
              data-ad-layout-key="-fb+5w+4e-db+86"
              data-ad-client="ca-pub-1728254718613711"
              data-ad-slot="6165439908"
            ></ins>
          </div>
        </div>
      </div>

      {/* <div className="message">
        <h4>
          Escríbenos en whatsapp:{" "}
          <a href="https://wa.me/573053361916">+573053361916</a> o haz{" "}
          <a href="https://wa.me/573053361916">clic aquí</a>
        </h4>
        <h4>
          O en nuestra página de facebook:{" "}
          <a href="https://www.facebook.com/paisamixplus">
            www.facebook.com/paisamixplus
          </a>
        </h4>
      </div> */}
      <div className="ad-container">
        <ins
          class="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-1728254718613711"
          data-ad-slot="6329091436"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>

      <div class="sharethis-inline-share-buttons"></div>

      <div className="chat">
        <button className="button" onClick={refreshComments}>
          Refrescar comentarios
        </button>
        <div
          className="fb-comments"
          data-href="https://www.bluemedellin.com"
          data-numposts="10"
          data-width=""
          data-order-by="reverse_time"
        ></div>
      </div>

      <div className="copytext">
        Si tienes alguna PQRS con la plataforma por favor comunicarse con{" "}
        <a href="mailto:contacto@bluemedellin.com">contacto@bluemedellin.com</a>
      </div>

      <Modal show={modal} setShow={setModal}>
        <iframe
          title="requestsong"
          src="https://fm.transmitir.me/public/blue_medellin/embed-requests"
          frameborder="0"
          allowtransparency="true"
          style={{
            width: "100%",
            height: "850px",
            maxHeight: "80vh",
            border: "0",
          }}
        ></iframe>
      </Modal>
    </div>
  );
}

export default App;
