import { useEffect } from "react";

const useOutsideClick = (ref, prevent, callback) => {
  const handleClick = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      e.target.id !== "arrow" &&
      e.target.id !== prevent
    ) {
      callback();
    }
  };

  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("keydown", handleEscape, false);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keydown", handleEscape, false);
    };
  });
};

export default useOutsideClick;
